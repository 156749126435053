import React from 'react'
import {
  makeStyles,
  Theme,
  createStyles,
  Avatar,
  Typography,
} from '@material-ui/core'
import InstagramIcon from '@material-ui/icons/Instagram'
import TwitterIcon from '@material-ui/icons/Twitter'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        height: '150px',
        width: '150px',
        border: '7px solid fade-out($black, 0.9)',
      },
    },
    icon: {},
    socialContainer: {},
    container: { textAlign: '-webkit-center', marginBottom: '30px' },
  })
)

interface Props {
  image: any
  altText?: string
  header?: string
  igHandle?: string
  twitterHandle?: string
}

const AvatarComponent: React.FC<Props> = ({
  image,
  igHandle,
  twitterHandle,
  header,
  altText = 'default',
}) => {
  const classes = useStyles()

  const handleIgOnClick = () => {
    window.open(`http://www.instagram.com/${igHandle}`)
  }
  const handleTwitterOnClick = () => {
    window.open(`http://www.twitter.com/${twitterHandle}`)
  }

  return (
    <div className={classes.container}>
      <div className={classes.root}>
        <Avatar alt={altText} src={image} />
        <Typography variant={'caption'}>{header}</Typography>
      </div>
      <div>
        {igHandle && (
          <button onClick={handleIgOnClick} style={{ border: 'none' }}>
            <InstagramIcon fontSize={'large'} className={classes.icon} />
          </button>
        )}
        {twitterHandle && (
          <button onClick={handleTwitterOnClick} style={{ border: 'none' }}>
            <TwitterIcon fontSize={'large'} className={classes.icon} />
          </button>
        )}
      </div>
    </div>
  )
}

export default AvatarComponent
