import React from 'react'
import {
  Typography,
  Container,
  Grid,
  makeStyles,
  Theme,
  createStyles,
  Divider,
  Hidden,
} from '@material-ui/core'
import { Layout } from '../components/layout'
import { graphql } from 'gatsby'

import ImageMasonry from 'react-image-masonry'
import yanni from '../images/yanni-avatar.jpg'
import AvatarComponent from '../components/avatar/Avatar'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
      marginBottom: '32px',
    },
    gridList: {
      // width: 500,
      // height: 450,
      //   maxHeight: '1000x',
    },
  })
)

interface Props {
  data: any
}

const Photography: React.FC<Props> = ({ data }) => {
  const classes = useStyles()
  console.log('data', data)
  const [images, updateImages] = React.useState([])

  const { nodes } = data.allContentfulPhotography
  console.log('nodes', nodes)
  React.useEffect(() => {
    console.log('nodes', nodes)

    if (nodes.length > 0) {
      const tempImages = nodes.map((n: any) => {
        if (n.image.sizes !== null || n.image.sizes !== undefined) {
          return `https:${n.image.sizes.src}`
        }
      })
      console.log('tempImages', tempImages)
      updateImages(tempImages)
    }
  }, [data])

  return (
    <Layout>
      <Container className={classes.root}>
        <Grid justify={'center'} container style={{ marginBottom: '32px' }}>
          <Grid alignContent={'center'} item md={12}>
            <Typography
              gutterBottom
              variant={'h1'}
              style={{ textAlign: 'center', margin: '32px' }}
            >
              Photography
            </Typography>
            <Divider variant={'middle'} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <AvatarComponent
              altText={'YANTHEDON'}
              igHandle={'yanthedon'}
              image={yanni}
              header={'YANTHEDON'}
            />
          </Grid>
          <Grid item xs={12}>
            <Hidden mdDown>
              <ImageMasonry imageUrls={[...images]} numCols={5} />
            </Hidden>
          </Grid>
          {/* Only displayed on mobile */}
          <Hidden mdUp>
            <ImageMasonry imageUrls={[...images]} numCols={1} />
          </Hidden>
        </Grid>
      </Container>
    </Layout>
  )
}

export default Photography

export const query = graphql`
  query PhotographyQuery {
    allContentfulPhotography {
      nodes {
        image {
          title
          description
          contentful_id
          sizes {
            src
          }
        }
      }
    }
  }
`
